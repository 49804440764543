<template>
  <div class="SelectBox">
    <div class="aselect" :data-value="value" :data-list="options">
      <div class="selector" @click="toggle()">
        <div class="label">
          <span>{{ value.name }}</span>
        </div>
        <img
          src="@/assets/imgs/arrow-down.svg"
          class="arrow"
          :class="{ expanded: visible }"
        />
        <div :class="{ hidden: !visible, visible }">
          <ul>
            <li
              v-for="(item, index) in options"
              :key="index"
              class="option"
              :class="{ current: item === value }"
              @click="onChange(item)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    setValue: {
      type: String,
      default: "Выберите тематику",
    },
  },
  data() {
    return {
      value: {
        name: "",
      },
      visible: false,
    };
  },
  created() {
    this.value.name = this.options[0]?.name;
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
    },
    onChange(option) {
      this.value = option;
      this.$emit("onchange", option);
    },
  },
};
</script>

<style lang="scss" scoped>
.aselect {
  cursor: pointer;
  font-family: "Display Regular";
  .selector {
    width: 225px;
    border: 1px solid #ff8025;
    position: relative;
    margin: 0 15px;
    border-radius: 4px;
    z-index: 1;
    color: #ff8025;
    background: #fff;
    .arrow {
      position: absolute;
      right: 10px;
      top: 40%;
      transform: rotateZ(0deg) translateY(0px);
      transition-duration: 0.3s;
      transition-timing-function: cubic-bezier(0.59, 1.39, 0.37, 1.01);
    }
    .expanded {
      transform: rotateZ(180deg) translateY(2px);
    }
    .label {
      display: block;
      padding: 6px 12px;
      font-size: 13px;
      color: #434343;
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 6px 12px;
    margin: 0;
    margin-top: 10px;
    font-size: 13px;
    position: absolute;
    z-index: 1;
    background: #fff;
    border: 1px solid #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  li.option {
    padding: 6px 0;
    color: #434343;
    &:hover {
      color: #ff8025;
    }
  }
  /*.current {*/
  /*  background: #eaeaea;*/
  /*}*/
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
}
</style>
