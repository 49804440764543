<template>
  <div class="top_part">
    <Header v-model="tab" />
    <Background v-if="tab == 'bg'" />
    <TextAndLogo v-else-if="tab == 'text'" />
  </div>
</template>

<script>
import Header from "./header";
import Background from "./background";
import TextAndLogo from "./text-and-logo";

import { mapFields } from "vuex-map-fields";

import { canvas } from "@/pages/constructor-car/__car-canvas__";

export default {
  components: {
    Header,
    Background,
    TextAndLogo,
  },
  data() {
    return {
      tab: "text",
    };
  },
  computed: {
    ...mapFields(["isFigsEditable", "bgGradientPointIndx"]),
  },
  watch: {
    tab: {
      handler(val) {
        if (val === "text") {
          this.isFigsEditable = true;
          this.bgGradientPointIndx = null;
        } else {
          this.isFigsEditable = false;
          canvas.discardActiveObject().renderAll();
        }
      },
      immediate: true,
    },
  },
};
</script>
