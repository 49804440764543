import { difference as diff, union } from "lodash-es";

import {
  hexaNormalize,
  hexaToRgba,
  rgbaToHexa,
  rgbToHsv,
} from "@/components/vue-color-gradient-picker/helpers";
import { mapFields } from "vuex-map-fields";

export default {
  name: "Palette",

  props: {
    points: Array,
    activePointIndex: Number,
    red: Number,
    green: Number,
    blue: Number,
    alpha: Number,
    hue: Number,
    saturation: Number,
    value: Number,
    updateColor: Function,
  },

  computed: {
    ...mapFields([
      "customPaletteBg",
      "customPaletteBgIndx",
      "bgGradientPointIndx",
      "settings",
    ]),
    palette() {
      return this.settings?.menu.background.gradient.options.map((h) =>
        hexaNormalize(h)
      );
    },
    hexa() {
      return rgbaToHexa({
        red: this.red,
        green: this.green,
        blue: this.blue,
        alpha: this.alpha,
      });
    },
  },

  watch: {
    points: {
      handler() {
        this.buildCustomPaletteBg();
      },
      deep: true,
    },
    hexa() {
      this.buildCustomPaletteBg();
    },
    activePointIndex(val) {
      if (this.bgGradientPointIndx !== null) {
        this.bgGradientPointIndx = val;
      }
    },
    bgGradientPointIndx(val, prev) {
      if (val !== prev) {
        if (val !== null) {
          document.addEventListener("keydown", this.onKeydown);
          setTimeout(() => document.addEventListener("click", this.onKeydown), 500)
        } else {
          document.removeEventListener("keydown", this.onKeydown);
          document.removeEventListener("click", this.onKeydown);
        }
      }
    },
  },

  methods: {
    onKeydown() {
      this.bgGradientPointIndx = null;
    },
    buildCustomPaletteBg() {
      if (!this.points) {
        return;
      }
      if (this.customPaletteBgIndx !== null)
        this.customPaletteBg[this.customPaletteBgIndx] = this.hexa;

      const points = this.points.map((p) => rgbaToHexa(p));

      const pointsDiffPalette = diff(points, this.palette);

      const cPaletteDiffPoints = diff(this.customPaletteBg, points);

      this.customPaletteBg = union(cPaletteDiffPoints, pointsDiffPalette).slice(
        -15
      );

      this.customPaletteBgIndx = this.customPaletteBg.findIndex(
        (c) => c === this.hexa
      );
    },
    setColor(c, indx) {
      if (!c) return;
      this.customPaletteBgIndx = indx;
      const rgba = hexaToRgba(c);
      const hsv = rgbToHsv(rgba);
      this.updateColor({ ...rgba, ...hsv }, "onChange");
    },
    togglePipette() {
      this.bgGradientPointIndx =
        this.bgGradientPointIndx === null ? this.activePointIndex : null;
    },
  },
};
