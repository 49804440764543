<template>
  <li>
    <a>
      <img v-svg-inline src="@/assets/imgs/image.svg" alt="" />
      Изображение
    </a>
    <div class="dropleft-content-construct-menu">
      <div class="dropleft-holder">
        <div class="dropleft-header">Изображение</div>
        <hr />
        <UploadImg :value="_value" @input="setBgImage({ url: $event })" />
      </div>
    </div>
  </li>
</template>

<script>
import UploadImg from "../components/upload-img";

export default {
  name: "ImageBg",
  components: { UploadImg },
  props: {
    setBgImage: {
      type: Function,
      default: () => {},
    },
    bg: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    _value() {
      return this.bg?.props?.url;
    },
  },
};
</script>
