var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"dropleft-items dropleft-items__color"},[_vm._l((_vm.palette),function(c){return _c('div',{key:c,staticClass:"dropleft-bg"},[_c('div',{class:['dropleft-item', 'enable', { active: c === _vm.hexa }],style:({ background: c }),on:{"click":function($event){return _vm.setColor(c, null)}}})])}),_vm._l((15),function(i){return _c('div',{key:i,staticClass:"dropleft-bg"},[_c('div',{class:[
        'dropleft-item',
        { enable: _vm.customPaletteBg[i - 1] },
        { active: _vm.customPaletteBg[i - 1] === _vm.hexa },
      ],style:(_vm.customPaletteBg[i - 1]
          ? `background: ${_vm.customPaletteBg[i - 1]}`
          : 'background: #f9f9f9'),on:{"click":function($event){return _vm.setColor(_vm.customPaletteBg[i - 1], i)}}})])}),_c('div',{staticClass:"dropleft-item pipette",class:{ active: _vm.bgGradientPointIndx !== null },on:{"click":_vm.togglePipette}},[_c('img',{directives:[{name:"svg-inline",rawName:"v-svg-inline"}],attrs:{"src":require("@/assets/imgs/pipette.svg"),"alt":""}})])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }