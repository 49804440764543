<template>
  <div class="auto_bottom">
    <div class="logo_bottom">
      <div id="parent-arrow-position">
        <template v-for="a in settings.viewsCar.arrows">
          <img
            v-if="cars[carIndx].views && cars[carIndx].views[a.id]"
            :id="`arrow-position-${a.id}`"
            :key="a.id"
            class="arrow-position"
            :class="{ active: a.id === viewId }"
            :src="a.img"
            @click="viewId = a.id"
          />
        </template>
      </div>
      <img :src="settings.viewsCar.views[viewId]" />
    </div>
    <div class="scrolling_items">
      <div
        v-for="(c, indx) in cars"
        :key="indx"
        class="item"
        :class="{ active: indx == carIndx }"
        @click="selectCar(indx)"
      >
        <img
          v-if="c.vcq.variant && c.vcq.variant.preview"
          :src="c.vcq.variant && c.vcq.variant.preview"
          :style="`background: #${c.vcq.color}`"
        />
        <img v-else :src="settings.previewEmpty" />
        {{ indx + 1 }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapMultiRowFields(["cars"]),
    ...mapFields(["settings", "carIndx", "viewId"]),
  },
  methods: {
    selectCar(indx) {
      this.carIndx = indx;
      if (!this.cars[indx]?.views || !this.cars[indx]?.views[this.viewId]) {
        this.viewId = "left";
      }
    },
  },
};
</script>
