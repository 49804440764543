<template>
  <div class="menuMain">
    <Top />
    <button
      class="btn footer btnMaim"
      @click="goTo({ name: 'create-order' }, 'construct')"
    >
      Продолжить<i class="far fa-long-arrow-right"></i>
    </button>
  </div>
</template>

<script>
import Top from "./top";

export default {
  components: {
    Top,
  },
  methods: {
    goTo(to, design) {
      this.$router.push(to).catch((err) => {
        throw new Error(`Route from choice-design.index: ${err}.`);
      });
      this.design = design;
    },
  },
};
</script>
