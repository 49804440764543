<template>
  <label class="UploadImg">
    <img class="img" :src="value" alt="" />
    <div class="text">
      <p class="title">Загрузить</p>
      <p class="subtitle">изображение до 12 Мб</p>
      <img v-svg-inline src="@/assets/imgs/download.svg" alt="" />
    </div>
    <input
      class="input"
      type="file"
      name="file-input"
      :value="files"
      @change="handleFileInput"
    />
  </label>
</template>

<script>
// https://dev.to/hundredrab/yet-another-vue-drag-and-drop-15m2
// this tip, convert FileList to array, credit: https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
import axios from "axios";
import config from "@/config";
export default {
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      files: null,
    };
  },
  methods: {
    async handleFileInput(e) {
      const files = e.target.files;
      if (!files || !files.length) return;
      const value = await this.send(Array.from(files));
      this.$emit("input", value);
      this.files = null;
    },
    async send(files) {
      const formData = new FormData();
      files.forEach((f, i) => {
        formData.append(`files[${i}]`, f);
      });
      try {
        const res = await axios.post(
          `${config.API_URL}/constructor/uploadImg`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        return res.data.result;
        // eslint-disable-next-line no-empty
      } catch (e) {}
    },
  },
};
</script>

<style lang="scss" scoped>
.UploadImg {
  position: relative;
  display: flex;
  cursor: pointer;
  min-height: 120px;
  background-color: #cedad6;
  margin-bottom: 10px;

  .img {
    display: block;
    width: 100%;
  }

  .text {
    position: absolute;
    left: 0;
    top: 0;
    width: 272px;
    font-family: "Display Medium";
    text-align: center;
    color: white;
    stroke: white;

    svg {
      width: 21px;
      height: 21px;
    }
  }

  .input {
    position: absolute;
    opacity: 0;
  }
}
</style>
