<template>
  <div>
    <ul :key="viewId" class="list_points">
      <Gradient
        :set-bg-gradient="builderSetBg(menuBg.gradient)"
        :bg="gradient"
      />
      <ImageBg :set-bg-image="builderSetBg(menuBg.image)" :bg="image" />
      <TemplateBg
        :set-bg-template="builderSetBg(menuBg.template)"
        :bg="template"
        :menu-bg-temp="menuBg.template"
      />
      <li>
        <a @click="resetBg">
          <img v-svg-inline src="@/assets/imgs/wo-background.svg" alt="" />
          Удалить фон
        </a>
      </li>
    </ul>

    <!--    <Part v-if="['left', 'right'].includes(viewId)" :car-view="carView" />-->
  </div>
</template>

<script>
import Gradient from "./gradient";
import ImageBg from "./image";
import TemplateBg from "./template";
// import Part from "./part";

import { mapFields, createHelpers } from "vuex-map-fields";
const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

export default {
  components: { Gradient, ImageBg, TemplateBg },
  data() {
    return {
      gradient: {},
      image: {},
      template: {},
    };
  },
  computed: {
    ...mapFields(["settings", "viewId"]),
    ...mapCarFields({ carViews: "views" }),
    menuBg() {
      return this.settings?.menu.background;
    },
    carView() {
      return this.carViews[this.viewId];
    },
    bgs: {
      get() {
        return this.carView?.backgrounds;
      },
      set(val) {
        this.carView.backgrounds = val;
      },
    },
    actualBackground() {
      let answer = [this.gradient, this.image, this.template];
      answer.filter((_) => !!_);
      return answer;
    } /*,
    bg() {
      return this.bgs[0] ?? { props: {} };
    },*/,
  },
  methods: {
    setBg(menu, option, buildChildren) {
      if (menu.id === "bg-image" && !option.url) return;

      const bg = {
        id: menu.id,
        type: menu.type,
        props: menu.props,
      };
      if (menu.children && buildChildren) {
        bg.children = buildChildren(menu, option);
      } else {
        bg.props = { ...bg.props, ...option };
      }

      switch (menu.id) {
        case "bg-rectangle-gradient":
          this.gradient = bg;
          this.bgs = this.actualBackground;
          break;
        case "bg-image":
          this.image = bg;
          this.bgs = this.actualBackground;
          break;
        case "bg-template":
          this.template = bg;
          this.bgs = this.actualBackground;
          break;
        default:
          this.resetBg();
          this.bgs = [bg];
      }

      this.carViews = { ...this.carViews }; // to update in Vuex
      //bg-rectangle-gradient, bg-image, bg-template
    },
    builderSetBg(menu) {
      return (option, buildChildren) => this.setBg(menu, option, buildChildren);
    },
    resetBg() {
      this.bgs = [];
      this.gradient = {};
      this.template = {};
      this.image = {};
      this.carViews = { ...this.carViews }; // to update in Vuex
    },
  },
};
</script>
