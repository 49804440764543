<template>
  <ul class="canvasComponents">
    <template v-if="bar === 'text'">
      <li class="components"><a>T</a></li>
      <li class="components">
        <a href="#">{{ toolbar.text.fontFamily }}</a>
        <ul class="in_list font">
          <li
            v-for="ff in $options.fontFamilies"
            :key="ff"
            @click="toolbar.text.fontFamily = ff"
          >
            <a>{{ ff }}</a>
          </li>
        </ul>
      </li>
      <li class="components">
        <a>
          {{ toolbar.text.fontSize }} см
          <div class="arrows">
            <a @click="decFontSize"><i class="far fa-angle-up"></i></a>
            <a @click="incFontSize"><i class="far fa-angle-down"></i></a>
          </div>
        </a>
        <ul class="in_list size">
          <li v-for="fs in $options.fontSizes" :key="fs">
            <a @click="toolbar.text.fontSize = fs">{{ fs }}</a>
          </li>
        </ul>
      </li>
      <li class="components">
        <a>
          <span>{{ statusTextDecoration }}</span>
        </a>
        <ul class="in_list style">
          <li v-for="(v, k) in $options.textDecorations" :key="v.shortcut">
            <a @click="toggle(k)">{{ v.shortcut }}</a>
          </li>
        </ul>
      </li>
      <li class="components">
        <a><span :style="{ borderColor: toolbar.text.fill }">A</span></a>
        <ul class="in_list color">
          <li v-for="c in $options.colors" :key="c">
            <a @click="toolbar.text.fill = c"><span></span></a>
          </li>
        </ul>
      </li>
    </template>
    <template v-else-if="bar === 'fig'">
      <li class="components">
        <a>
          <svg width="28" height="28">
            <circle r="10" cx="14" cy="14" :fill="toolbar.figure.fill"></circle>
          </svg>
        </a>
        <ul class="in_list color">
          <li v-for="c in $options.colors" :key="c">
            <a @click="toolbar.figure.fill = c"><span></span></a>
          </li>
        </ul>
      </li>
      <li key="stroke" class="components">
        <a>
          <svg width="28" height="28">
            <circle
              r="10"
              cx="14"
              cy="14"
              fill="white"
              :stroke="toolbar.figure.stroke"
              stroke-width="4"
            ></circle>
          </svg>
        </a>
        <ul class="in_list color">
          <li v-for="c in $options.colors" :key="c">
            <a @click="toolbar.figure.stroke = c"><span></span></a>
          </li>
        </ul>
      </li>
    </template>
    <template v-else-if="bar === 'sticker'">
      <li class="components">
        <a>
          <svg width="28" height="28">
            <circle
              r="10"
              cx="14"
              cy="14"
              :fill="toolbar.sticker.fill"
            ></circle>
          </svg>
        </a>
        <ul class="in_list color">
          <li v-for="c in $options.colors" :key="c">
            <a @click="toolbar.sticker.fill = c"><span></span></a>
          </li>
        </ul>
      </li>
    </template>
    <template v-if="bar && bar !== 'bg'">
      <li class="components">
        <a @click="move(1)">
          <img v-svg-inline src="@/assets/imgs/bring-to-front.svg" alt="" />
        </a>
        <a @click="move(-1)">
          <img v-svg-inline src="@/assets/imgs/bring-to-back.svg" alt="" />
        </a>
      </li>
      <li class="components">
        <a @click="copy">
          <img v-svg-inline src="@/assets/imgs/copy.svg" alt="" />
        </a>
      </li>
      <li class="components">
        <a @click="remove">
          <i class="fas fa-trash-alt"></i>
        </a>
      </li>
    </template>
  </ul>
</template>

<script>
const clone = require("rfdc")();

import { mapFields, createHelpers } from "vuex-map-fields";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

export default {
  fontFamilies: [
    "Arial",
    "Open Sans",
    "Myriad Pro Medium",
    "Helvetica",
    "Montserrat",
    "Tahoma",
    "Verdana",
  ],
  fontSizes: [
    24, 28, 32, 36, 40, 50, 60, 70, 80, 90, 100, 120, 160, 180, 200, 220, 240,
    260,
  ],
  textDecorations: {
    fontWeight: { shortcut: "B", false: "normal", true: "bold" },
    fontStyle: { shortcut: "I", false: "normal", true: "italic" },
    underline: { shortcut: "U", false: false, true: true },
    linethrough: { shortcut: "S", false: false, true: true },
  },
  colors: [
    "#fff",
    "#f3e95b",
    "#ecc634",
    "#ce4a2c",
    "#e4e4e3",
    "#ceda57",
    "#a1c65b",
    "#b91e62",
    "#7d7d7e",
    "#74bcc6",
    "#5ea588",
    "#77398f",
    "#1e1e1e",
    "#6694ce",
    "#4b519f",
    "#5c4396",
  ],
  computed: {
    ...mapFields(["viewId", "fObjSelectedId", "toolbar"]),
    ...mapCarFields({ carViews: "views" }),
    bar() {
      return this.fObjSelectedId?.split("-").shift();
    },
    statusTextDecoration() {
      return (
        Object.entries(this.$options.textDecorations)
          .filter((kv) => this.toolbar.text[kv[0]] === kv[1][true])
          .map((kv) => kv[1].shortcut)
          .join("") || "N"
      );
    },
    fontSizeIndx() {
      return this.$options.fontSizes.findIndex(
        (fs) => fs === this.toolbar.text.fontSize
      );
    },
  },
  methods: {
    toggle(key) {
      if (this.toolbar.text[key] === this.$options.textDecorations[key][true]) {
        this.toolbar.text[key] = this.$options.textDecorations[key][false];
      } else {
        this.toolbar.text[key] = this.$options.textDecorations[key][true];
      }
    },
    incFontSize() {
      if (this.fontSizeIndx < this.$options.fontSizes.length - 1) {
        this.toolbar.text.fontSize =
          this.$options.fontSizes[this.fontSizeIndx + 1];
      }
    },
    decFontSize() {
      if (this.fontSizeIndx > 0) {
        this.toolbar.text.fontSize =
          this.$options.fontSizes[this.fontSizeIndx - 1];
      }
    },

    getFigsIndx() {
      if (!this.carViews || !this.carViews[this.viewId] || !this.fObjSelectedId)
        return null;
      const view = this.carViews[this.viewId];
      const figs = view.figures;
      const indx = figs.findIndex((f) => f.id === this.fObjSelectedId);
      return { view, figs, indx };
    },

    move(step) {
      const res = this.getFigsIndx();
      if (!res || !res.figs || res.indx === -1) return null;
      const { figs, indx } = res;
      const newIndx = indx + step;
      if (newIndx < 0 || newIndx >= figs.length) return null;
      figs.splice(indx + step, 0, figs.splice(indx, 1)[0]);
      this.carViews = { ...this.carViews }; // to update in Vuex
    },

    copy() {
      const res = this.getFigsIndx();
      if (!res || !res.figs || res.indx === -1) return null;
      const { view, figs, indx } = res;
      const newFig = clone(figs[indx]);
      const newId = newFig.prefId + view.nextNumId++;
      figs.push({
        ...newFig,
        id: newId,
        props: {
          ...newFig.props,
          left: newFig.props.left - 100,
          top: newFig.props.top - 10,
        },
      });
      this.fObjSelectedId = newId;

      this.carViews = { ...this.carViews }; // to update in Vuex
    },

    remove() {
      const res = this.getFigsIndx();
      if (!res || !res.figs || res.indx === -1) return null;
      const { figs, indx } = res;
      figs.splice(indx, 1);
      this.carViews = { ...this.carViews }; // to update in Vuex
    },
  },
};
</script>
