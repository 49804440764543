<template>
  <div class="design_choice">
    <div class="choice">
      <Top
        :prev="{ text: 'К выбору дизайна', name: 'index' }"
        :state="{ value: 2, text: 'Создай свой дизайн' }"
      />
      <Toolbar />

      <CarCanvas />

      <CarView />
    </div>
    <Menu />
  </div>
</template>

<script>
import { mapActions } from "vuex";

import Top from "../__top__";
import Toolbar from "./__toolbar__";
import CarView from "./__car-view__";
import CarCanvas from "./__car-canvas__";
import Menu from "./__menu__";

export default {
  components: {
    Top,
    Toolbar,
    CarView,
    CarCanvas,
    Menu,
  },
  created() {
    this.featchTempCars();
  },
  methods: {
    ...mapActions(["featchTempCars"]),
  },
};
</script>
