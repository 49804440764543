<template>
  <ul class="list_points">
    <li>
      <a @click="add(menuTextAndLogo.text)">
        <img v-svg-inline src="@/assets/imgs/text.svg" alt="" />
        Текст
      </a>
    </li>
    <li>
      <a>
        <img v-svg-inline src="@/assets/imgs/logo-and-images.svg" alt="" />
        Логотип и изображение
      </a>
      <div class="dropleft-content-construct-menu">
        <div class="dropleft-holder">
          <div class="dropleft-header">Логотип и изображение</div>
          <hr />
          <UploadImg
            :value="toolbar.image.url"
            @input="addImg(menuTextAndLogo.image, { url: $event })"
          />
        </div>
      </div>
    </li>
    <li>
      <a>
        <img v-svg-inline src="@/assets/imgs/figures.svg" alt="" />
        Фигуры
      </a>
      <div class="dropleft-content-construct-menu">
        <div class="dropleft-holder">
          <div class="dropleft-header">Фигуры</div>
          <hr />
          <!--eslint-disable vue/no-v-html-->
          <div class="dropleft-items">
            <div
              v-for="(f, key) in menuTextAndLogo.figures"
              :key="key"
              class="dropleft-item"
              @click="add(f.fObj)"
              v-html="f.menuItem"
            ></div>
          </div>
          <!--eslint-enable-->
        </div>
      </div>
    </li>
    <li>
      <a>
        <img v-svg-inline src="@/assets/imgs/stickers-and-clipart.svg" alt="" />
        Стикеры и клипарт
      </a>
      <div class="dropleft-content-construct-menu">
        <div class="dropleft-holder">
          <div class="dropleft-header">Стикеры и клипарт</div>
          <hr />
          <select-box :options="stickersThemes" @onchange="onChange" />
          <!--eslint-disable vue/no-v-html-->
          <div class="dropleft-items stickers">
            <div
              v-for="(f, key) in menuTextAndLogo.stickers"
              v-show="f.section.id === stickersThemeId"
              :key="key"
              class="dropleft-item"
              @click="add(f.fObj)"
              v-html="f.menuItem"
            ></div>
          </div>
          <!--eslint-enable-->
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import SelectBox from "@/components/UI/SelectBox.vue";

const clone = require("rfdc")();

import { mapFields, createHelpers } from "vuex-map-fields";

const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

import UploadImg from "./components/upload-img";

const TOOLBAR_TO_RESOLVE = [
  "text.fill",
  "text.fontFamily",
  "text.fontSize",
  "text.fontWeight",
  "text.fontStyle",
  "text.underline",
  "text.linethrough",
  "text.text",
  "figure.fill",
  "figure.stroke",
  "sticker.fill",
];

export default {
  components: { SelectBox, UploadImg },
  computed: {
    ...mapFields(["settings", "viewId", "isFObjAdded", "toolbar"]),
    ...mapCarFields({ carViews: "views" }),
    menuTextAndLogo() {
      return this.settings?.menu.textAndLogo;
    },
    stickersThemes() {
      let themes = [
        {
          id: null,
          name: "Без раздела",
        },
      ];
      for (let key in this.menuTextAndLogo?.stickers) {
        if (this.menuTextAndLogo?.stickers[key]?.section.id) {
          if (
            !themes.find(
              (_) => _.id === this.menuTextAndLogo?.stickers[key]?.section.id
            )
          ) {
            themes.push(this.menuTextAndLogo?.stickers[key]?.section);
          }
        }
      }

      return themes;
    },
  },
  data() {
    return {
      stickersThemeId: null,
    };
  },
  methods: {
    onChange(value) {
      this.stickersThemeId = value.id;
    },
    add(obj) {
      const view = this.carViews[this.viewId];
      const id = obj.prefId + view.nextNumId++;
      const o = this.resolve(obj);
      view.figures.push({ ...o, id });
      this.isFObjAdded = true;

      this.carViews = { ...this.carViews }; // to update in Vuex
    },
    resolve(obj) {
      const o = clone(obj);
      Object.entries(o.props || {}).forEach((kv) => {
        TOOLBAR_TO_RESOLVE.forEach((compKey) => {
          const keys = compKey.split(".");
          if (kv[1] === `{{${compKey}}}`) {
            o.props[kv[0]] = this.toolbar[keys[0]][keys[1]];
          }
        });
      });
      return o;
    },
    addImg(menu, option) {
      const view = this.carViews[this.viewId];
      const id = menu.prefId + view.nextNumId++;
      view.figures.push({
        id,
        prefId: "image-",
        type: menu.type,
        props: { ...menu.props, ...option },
      });
      this.isFObjAdded = true;

      this.carViews = { ...this.carViews }; // to update in Vuex
    },
  },
};
</script>
