<template>
  <li @mouseenter="shownGradient = true">
    <a>
      <img v-svg-inline src="@/assets/imgs/color-and-grad.svg" alt="" />
      Цвет и градиент
    </a>
    <div class="dropleft-content-construct-menu" style="width: 320px">
      <div class="dropleft-holder">
        <div class="dropleft-header">
          <div
            class="solid dropleft-header__text"
            :class="{ active: !isGradient }"
            @click="isGradient = false"
          >
            Цвет
          </div>
          <div
            class="dropleft-header__text"
            :class="{ active: isGradient }"
            @click="isGradient = true"
          >
            Градиент
          </div>
        </div>

        <hr />
        <div v-if="shownGradient">
          <ColorPicker
            :is-gradient="isGradient"
            :gradient="gradient"
            :on-start-change="
              (color) => setBgGradient(color, isGradient && buildChildren)
            "
            :on-change="
              (color) => setBgGradient(color, isGradient && buildChildren)
            "
            :on-end-change="
              (color) => setBgGradient(color, isGradient && buildChildren)
            "
          />
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { ColorPicker } from "@/components/vue-color-gradient-picker";
import {
  rgbaToHexa,
  hexaToRgba,
} from "@/components/vue-color-gradient-picker/helpers";

export default {
  name: "Gradient",
  components: { ColorPicker },
  props: {
    setBgGradient: {
      type: Function,
      default: () => {},
    },
    bg: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isGradient: true,
      shownGradient: false,
    };
  },
  computed: {
    gradient() {
      if (this.bg?.id !== "bg-rectangle-gradient" || !this.bg?.children)
        return undefined; // default gradient in picker

      const gradProps = this.bg.children[0].props;
      const { x1, y1, x2, y2 } = gradProps;
      const vector = { x1, y1, x2, y2 };
      return {
        type: gradProps.gradientType,
        degree: this.vectorToAngle(vector),
        points: gradProps.colorStops.map((c) => ({
          left: c.offset * 100,
          ...hexaToRgba(c.color),
        })),
      };
    },
  },
  methods: {
    buildChildren(menu, option) {
      const children = menu.children;
      children[0].props = {
        ...children[0].props,
        ...this.angleToVector(option.degree),
      };
      children[0].props.colorStops = option.points.map((p) => {
        const { left, ...rgba } = p;
        return {
          offset: left / 100,
          color: rgbaToHexa(rgba),
        };
      });
      return children;
    },
    vectorToAngle(vector) {
      const { x1, y1, x2, y2 } = vector;
      const radians = Math.atan2(x2 - x1, y2 - y1);
      const degree = radians * (180 / Math.PI) * -1 + 180;
      return Math.round(degree);
    },
    angleToVector(angle) {
      const w = this.bg?.props?.width;
      const h = this.bg?.props?.height;
      const PI = Math.PI,
        sin = Math.sin,
        cos = Math.cos,
        tan = Math.tan,
        atan = Math.atan;
      const a = (angle * PI) / 180;

      function end() {
        function f0(a) {
          const w1 = (h / 2) * tan(a);
          const w2 = w / 2 - w1;
          const d = w2 * sin(a);
          const y = d * cos(a);
          const w3 = d * sin(a);
          const x = w1 + w3;
          return [x, y];
        }
        function f1(a) {
          const h1 = w / 2 / tan(a);
          const h2 = h / 2 - h1;
          const d = h2 * cos(a);
          const h3 = d * cos(a);
          const y = h2 - h3;
          const x = d * sin(a);
          return [x, y];
        }

        if (0 < a && a <= atan(w / h)) {
          const f = f0(a);
          const x = w / 2 + f[0];
          const y = -f[1];
          return [x, y];
        } else if (atan(w / h) < a && a <= PI / 2) {
          const f = f1(a);
          const x = w + f[0];
          const y = f[1];
          return [x, y];
        } else if (PI / 2 < a && a <= PI - atan(w / h)) {
          const f = f1(PI - a);
          const x = w + f[0];
          const y = h - f[1];
          return [x, y];
        } else if (PI - atan(w / h) < a && a <= PI) {
          const f = f0(PI - a);
          const x = w / 2 + f[0];
          const y = h + f[1];
          return [x, y];
        } else if (PI < a && a <= atan(w / h) + PI) {
          const f = f0(a - PI);
          const x = w / 2 - f[0];
          const y = h + f[1];
          return [x, y];
        } else if (atan(w / h) + PI < a && a <= (3 * PI) / 2) {
          const f = f1(a - PI);
          const x = -f[0];
          const y = h - f[1];
          return [x, y];
        } else if ((3 * PI) / 2 < a && a <= 2 * PI - atan(w / h)) {
          const f = f1(2 * PI - a);
          const x = -f[0];
          const y = f[1];
          return [x, y];
        } else if (2 * PI - atan(w / h) < a && a <= 2 * PI) {
          const f = f0(2 * PI - a);
          const x = w / 2 - f[0];
          const y = -f[1];
          return [x, y];
        } else {
          return [500, 0];
        }
      }
      function start(e) {
        const c = [w / 2, h / 2];
        return [2 * c[0] - e[0], 2 * c[1] - e[1]];
      }

      const e = end();
      const s = start(e);

      const vector = {
        x1: s[0],
        y1: s[1],
        x2: e[0],
        y2: e[1],
      };
      return vector;
    },
  },
};
</script>
