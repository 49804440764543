<template>
  <div class="header">
    <a @click="showSelectionCar = !showSelectionCar">
      {{ vcq.variant ? vcq.variant.name : "Не указана" }}
      <i class="far fa-angle-down" :class="{ hidden: showSelectionCar }"></i>
      <i class="far fa-angle-up" :class="{ hidden: !showSelectionCar }"></i>
      <SelectCar v-if="showSelectionCar" v-model="carIndx" :cars="cars" />
    </a>
    <p>Применить дизайн от автомобиля:</p>
    <a
      class="btn_radius dropout"
      @click="showSelectionDesign = !showSelectionDesign"
    >
      {{ carNameWDesign || "Не применять" }}
      <i class="far fa-angle-down" :class="{ hidden: showSelectionDesign }"></i>
      <i class="far fa-angle-up" :class="{ hidden: !showSelectionDesign }"></i>
      <SelectCar
        v-if="showSelectionDesign"
        v-model="carIndxDesignApplied"
        :cars="cars"
        :except-indx="carIndx"
      />
    </a>
    <div class="btns_bottom">
      <button
        class="btn btn_bottom"
        :class="{ active: value === 'bg' }"
        @click="$emit('input', 'bg')"
      >
        Фон <i class="fal fa-info-circle"></i>
      </button>
      <button
        class="btn btn_bottom"
        :class="{ active: value === 'text' }"
        @click="$emit('input', 'text')"
      >
        Текст и логотип
      </button>
    </div>
  </div>
</template>

<script>
const clone = require("rfdc")();

import { mapFields, mapMultiRowFields, createHelpers } from "vuex-map-fields";
const { mapFields: mapCarFields } = createHelpers({
  getterType: "getCarField",
  mutationType: "updateCarField",
});

import SelectCar from "./select-car";

export default {
  components: { SelectCar },
  props: {
    value: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      showSelectionCar: false,
      showSelectionDesign: false,
    };
  },
  computed: {
    ...mapCarFields({ vcq: "vcq", carViews: "views" }),
    ...mapMultiRowFields(["cars"]),
    ...mapFields(["carIndx", "carIndxDesignApplied"]),
    carNameWDesign() {
      return this.cars[this.carIndxDesignApplied]?.vcq.variant.name;
    },
  },
  watch: {
    carIndxDesignApplied(val, prev) {
      if (val === null || val === prev) return;
      Object.keys(this.carViews).forEach((k) => {
        const fromView = this.cars[val].views[k];
        if (fromView) {
          let copiedDesign = clone(fromView);
          copiedDesign.fObjs = copiedDesign.fObjs.map((item) => {
            if (item?.id === "car") {
              let issetView = this.carViews[k].fObjs.find(
                (item) => item.id === "car"
              );
              if (issetView) {
                item = issetView;
              }
            }

            return item;
          });
          this.carViews[k] = copiedDesign;
        }
      });
    },
    carIndx() {
      this.carIndxDesignApplied = null;
    },
  },
};
</script>
