<template>
  <div class="dropdown-content-current-car table_nona">
    <table>
      <tbody>
        <template v-for="(c, i) in cars">
          <tr
            v-if="exceptIndx === null || i !== exceptIndx"
            :key="i"
            :class="{ active: i === value }"
            @click="$emit('input', i)"
          >
            <td>
              {{ c.vcq.variant ? c.vcq.variant.name : "Не указана" }}
            </td>
            <td>
              <span
                class="square_color"
                :style="`background-color: #${c.vcq.color}`"
              ></span>
            </td>
            <td>{{ c.vcq.quantity }} шт</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    cars: {
      type: Array,
      default: null,
    },
    exceptIndx: {
      type: Number,
      default: null,
    },
    value: { type: Number, default: null },
  },
};
</script>
