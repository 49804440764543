<template>
  <li>
    <a>
      <img v-svg-inline src="@/assets/imgs/templates.svg" alt="" />
      Шаблоны
    </a>
    <div class="dropleft-content-construct-menu">
      <div class="dropleft-holder">
        <div class="dropleft-header">Шаблоны</div>

        <hr />
        <div class="dropleft-subheader">Тематика:</div>
        <select-box :options="menuBgTemp.themes" @onchange="onChange" />
        <hr />
        <div class="dropleft-items template-items-scroll">
          <div
            v-for="(t, index) in filteredTemps"
            :key="t.name"
            class="dropleft-item template"
            @click="templateOnClick(t, index)"
          >
            <img
              :class="{ active: tempIndex === index }"
              :src="menuBgTemp.iconBaseURL + t.name"
            />
            <p class="template-name">Название</p>
          </div>
        </div>
        <hr />
        <div class="dropleft-subheader">Настройка размера</div>
        <div class="slidecontainer">
          <RangeSlider @onrangechange="onRangeChange" />
        </div>

        <hr />
        <div class="dropleft-subheader">Цветовая гамма</div>
        <div class="dropleft-items color-gam">
          <div
            v-for="s in menuBgTemp.schemes"
            :key="s.id"
            class="dropleft-item"
            :class="{ active: s.id === schemaId }"
            @click="schemaOnClick(s)"
          >
            <img :src="menuBgTemp.iconBaseURL + s.name" />
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import RangeSlider from "../../../../../components/UI/RangeSlider";
import SelectBox from "../../../../../components/UI/SelectBox";
export default {
  name: "TemplateBg",
  components: { SelectBox, RangeSlider },
  props: {
    setBgTemplate: {
      type: Function,
      default: () => {},
    },
    bg: {
      type: Object,
      default: () => ({}),
    },
    menuBgTemp: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      themeId: 1,
      sizeId: 1,
      schemaId: 1,
      tempIndex: 0,
      backgroundSize: "0% 100%",
      selectedTemplate: {},
    };
  },
  computed: {
    filteredTemps() {
      return this.menuBgTemp.options.filter(
        (t) =>
          t.themeId === this.themeId &&
          t.sizeId === +this.sizeId &&
          t.schemaId === this.schemaId
      );
    },
  },
  created() {
    this.selectedTemplate = this.menuBgTemp.themes[0];
    this.themeId = this.menuBgTemp.themes[0].id;
    this.schemaId = this.menuBgTemp.schemes[0].id;
  },
  methods: {
    updateBgTemplate(t) {
      this.setBgTemplate({
        url: this.menuBgTemp.imgBaseURL + t.name,
      });
    },
    templateOnClick(t, index) {
      this.selectedTemplate = t;
      this.tempIndex = index;
      this.updateBgTemplate(t);
    },
    schemaOnClick(s) {
      this.schemaId = s.id;
      this.selectedTemplate = this.filteredTemps[this.tempIndex];
      this.templateOnClick(this.selectedTemplate, this.tempIndex);
    },
    updateSlider() {
      // let clickedElement = e.target,
      //   min = clickedElement.min,
      //   max = clickedElement.max,
      //   val = clickedElement.value;

      this.backgroundSize = ((this.sizeId - 1) * 100) / (3 - 1) + "% 100%";
    },
    onThumbClick(action) {
      if (action === "-") {
        this.sizeId--;
        this.onRangeChange();
      } else {
        this.sizeId++;
        this.onRangeChange();
      }
      this.updateSlider();
    },
    onRangeChange(value) {
      this.sizeId = value;
      this.selectedTemplate = this.filteredTemps[this.tempIndex];
      this.templateOnClick(this.selectedTemplate, this.tempIndex);
    },
    onChange(value) {
      this.sizeId = 1;
      this.themeId = value.id;
      this.selectedTemplate = this.filteredTemps[this.tempIndex];
      this.templateOnClick(this.selectedTemplate, this.tempIndex);
    },
  },
};
</script>

<style scoped lang="scss">
.up {
  transform: rotate(180deg);
}
.down {
  transform: rotate(0deg);
}
</style>
