<template>
  <div class="RangeSlider">
    <div class="wrapper">
      <div class="round small" @click="onThumbClick(1)"></div>
      <div class="middle-line">
        <div
          class="line"
          :class="{ active: initialValue === 2 || initialValue > 2 }"
        ></div>
        <div
          class="round mid"
          :class="{ active: initialValue === 2 || initialValue > 2 }"
          @click="onThumbClick(2)"
        ></div>
      </div>
      <div class="big-line">
        <div class="line" :class="{ active: initialValue >= max }"></div>
        <div
          class="round big"
          :class="{ active: initialValue >= max }"
          @click="onThumbClick(3)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 3,
    },
    value: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      initialValue: 1,
    };
  },
  methods: {
    onThumbClick(num) {
      this.initialValue = num;
      this.$emit("onrangechange", num);
    },
  },
};
</script>

<style lang="scss" scoped>
.RangeSlider {
  width: 100%;
  .wrapper {
    cursor: pointer;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .round {
    border-radius: 50%;
    &.small {
      background: #ff8a00;
      width: 9px;
      height: 9px;
    }
    &.mid {
      width: 14px;
      height: 14px;
      background: #c4c4c4;
      position: absolute;
      &.active {
        background: #ff8a00;
      }
    }
    &.big {
      width: 19px;
      height: 19px;
      background: #c4c4c4;
      position: absolute;
      &.active {
        background: #ff8a00;
      }
    }
  }
  .line {
    height: 2px;
    width: 100%;
    background: #c4c4c4;
    border-radius: 5px;
    &.active {
      background: #ff8a00;
    }
  }
  .middle-line,
  .big-line {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
  }
}
</style>
